import React from "react"

// Libraries
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container"
import DownloadsSection from "components/downloads-sections"
import { ExternalButtonLink } from "components/buttons"


// Downloads
import QuickReferenceGuide from "assets/downloads/respiratory-health-test/Visby-Medical-Respiratory-Health-Test-Quick-Reference-Guide.pdf"
import InstructionsForUse from "assets/downloads/respiratory-health-test/Visby-Medical-Respiratory-Health-Instructions-For-Use.pdf"
import Anterior from "assets/downloads/respiratory-health-test/Visby-Medical-Respiratory-Health-Test-Anterior-Nasal-Specimen-Collection-Instructions.pdf"
import AnteriorSpanish from "assets/downloads/respiratory-health-test/Visby-Medical-Respiratory-Health-Test-Anterior-Nasal-Specimen-Collection-Instructions-Spanish.pdf"
import SpecificationSheet from "assets/downloads/respiratory-health-test/Visby-Medical-Respiratory-Health-Test-Specification-Sheet.pdf"
import Nasopharyngeal from "assets/downloads/respiratory-health-test/Visby-Medical-Respiratory-Health-Test-Point-of-Care-Nasopharyngeal-Collection-Instructions.pdf"
import FactSheetHealthcare from "assets/downloads/respiratory-health-test/Visby-Medical-Respiratory-Health-Fact-Sheet-For-Healthcare-Providers.pdf"


const StyledContainer = styled(Container)`
  max-width: 1100px;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  padding: 0 20px;

.qrCode {
	text-align: center; 
	${breakpoint.xs`
		text-align: left;
  `}
}

b {
	font-weight: 600;
}


${ExternalButtonLink} {
	margin-bottom: 40px;
	width: 100%; 
  
	${breakpoint.small`
		width: auto; 
	`}

	&:hover {
		color: ${colors.white}!important;
	}
  }


`

const StiProviderEducation = props => {
	return (
		<DownloadsSection
			id="respi-provider"
			viewAllowed={props.viewAllowed}
			data-filter="respiratory" data-filter-type="devices" data-section="provider"
			style={{ paddingTop: "120px", paddingBottom: "25px" }}
		>
			<StyledContainer>
				<div className="block__content">
					<h3>Provider Education<span className="subtitle"> -- Resources for individuals who diagnose and treat patients</span></h3>
				</div>

				<div className="block">
					<div className="block__material">
						<h4>Clinical Resources</h4>
						<ul className="downloadsList">
							<li>
								<a href={SpecificationSheet} target="_blank" rel="noopener noreferrer">Specification Sheet</a>
							</li>
							<li>
								<a href={QuickReferenceGuide} target="_blank" rel="noopener noreferrer">Quick Reference Guide</a>
							</li>
							<li>
								<a href={InstructionsForUse} target="_blank" rel="noopener noreferrer">Instructions for Use</a>
							</li>
							<li>
								<a href={Anterior} target="_blank" rel="noopener noreferrer">Anterior Nasal Specimen Collection Instructions — English</a>
							</li>
							<li>
								<a href={AnteriorSpanish} target="_blank" rel="noopener noreferrer">Anterior Nasal Specimen Collection Instructions — Spanish</a>
							</li>
							<li>
								<a href={Nasopharyngeal} target="_blank" rel="noopener noreferrer">Nasopharyngeal Specimen Collection Instructions</a>
							</li>
							<li>
								<a href={FactSheetHealthcare} target="_blank" rel="noopener noreferrer">Fact Sheet for Healthcare Providers</a>
							</li>
						</ul>
						<h4>Legacy Articles</h4>
						<ul className="downloadsList">
							<li>
								<a href="https://www.researchgate.net/publication/351237786_Analytical_Evaluation_of_Visby_Medical_RT-PCR_Portable_Device_for_Rapid_Detection_of_SARS-CoV-2/fulltext/609e4648a6fdcccacb522517/Analytical-Evaluation-of-Visby-Medical-RT-PCR-Portable-Device-for-Rapid-Detection-of-SARS-CoV-2.pdf" target="_blank" rel="noopener noreferrer"><b>Article:</b> Analytical Evaluation of Visby Medical RT-PCR Portable Device for Rapid Detection of SARS-CoV-2 (MDPI)</a>
							</li>
							<li>
								<a href="https://www.jabfm.org/content/35/1/96" target="_blank" rel="noopener noreferrer"><b>Article:</b> Exploring Workplace Testing with Real-Time Polymerase Chain Reaction SARS-CoV-2 Testing (JABFM)</a>
							</li>
						</ul>
					</div>
					<div className="block__material">
					<h4>Articles</h4>
						<ul className="downloadsList">
							<li>
								<a href="/news/respiratory-diseases-current-landscape/" target="_blank" rel="noopener noreferrer"><b>Viewpoint:</b> Respiratory Diseases – The Current Landscape</a>
							</li>
							<li>
								<a href="/news/covid-flu-ab-rsv/" target="_blank" rel="noopener noreferrer"><b>Viewpoint:</b> COVID-19, Influenza A, Influenza B, and Respiratory Syncytial Virus</a>
							</li>
							<li>
								<a href="https://www.thelancet.com/pdfs/journals/lanres/PIIS2213-2600(22)00226-0.pdf" target="_blank" rel="noopener noreferrer"><b>Article:</b> Onset and window of SARS-CoV-2 infectiousness and temporal correlation with symptom onset: a prospective, longitudinal, community cohort study (The Lancet)</a>
							</li>
							<li>
								<a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7088847/#:~:text=Nucleic%20acid%2Dtargeted%20molecular%20techniques%20enhance%20diagnostic%20sensitivity%20and%20specificity,pathogens%20causing%20respiratory%20tract%20infections" target="_blank" rel="noopener noreferrer"><b>Article:</b> Molecular Diagnosis of Viral Respiratory Infections (Curr Infect Dis Rep)</a>
							</li>
							<li>
								<a href="https://www.sciencedirect.com/science/article/pii/S0039914022002053" target="_blank" rel="noopener noreferrer"><b>Article:</b> Review of COVID-19 testing and diagnostic methods (Talanta)</a>
							</li>
							<li>
								<a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7467050/" target="_blank" rel="noopener noreferrer"><b>Article:</b> Testing for Severe Acute Respiratory Syndrome–Coronavirus 2: Challenges in Getting Good Specimens, Choosing the Right Test, and Interpreting the Results (Crit Care Med)</a>
							</li>
						</ul>

					</div>
				</div>
				<ExternalButtonLink href="https://doodle.com/bp/alliepriego/visby-provider-meetingallie-priego" target="_blank" rel="noopener noreferrer" backgroundcolor="orange">Schedule Provider Training</ExternalButtonLink>
			</StyledContainer>
		</DownloadsSection>
	)
}



export default StiProviderEducation
